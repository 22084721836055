import styled from "styled-components"

import { ProfileButton } from "src/components/AppLayout/ProfileButton"
import {
  IconBox,
  LogoBox,
  NavFooter,
  NavMain,
  ProfileButtonSpacer,
  SideNavBox,
  StyledDivider,
  StyledNavLink,
} from "src/components/AppLayout/SideNav/sharedStyles"
import { HREF_MINUT_HELP, HREF_MINUT_STORE } from "src/constants/hrefs"
import { DEVICE_LIST_LIMIT } from "src/data/devices/logic/deviceConstants"
import { EVENT_LIST_LIMIT } from "src/data/events/logic/eventConstants"
import { useFeatureAvailability } from "src/data/featureAvailability/logic/useFeatureAvailability"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { getAccessLogic } from "src/data/user/logic/accessLogic"
import { useDunning } from "src/data/user/logic/dunningLogic"
import { usePermissions } from "src/data/user/logic/usePermissions"
import { useFlags } from "src/hooks/useFlags"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { colorsLegacy, mColors } from "src/ui/colors"
import { CustomLogo } from "src/ui/CustomLogo/CustomLogo"
import AddCircleIcon from "src/ui/icons/add-circle-outline.svg"
import EventsIcon from "src/ui/icons/bell.svg"
import StoreIcon from "src/ui/icons/cart.svg"
import DashboardIcon from "src/ui/icons/dashboard.svg"
import GiftIcon from "src/ui/icons/gift.svg"
import GuardAssistIcon from "src/ui/icons/guard-assist-outlined.svg"
import GuestConnectIcon from "src/ui/icons/guest-connect.svg"
import HomeIcon from "src/ui/icons/home.svg"
import IntegrationsIcon from "src/ui/icons/integration.svg"
import Logo from "src/ui/icons/logo.svg"
import SensorIcon from "src/ui/icons/sensor.svg"
import SettingsIcon from "src/ui/icons/sliders.svg"
import SupportIcon from "src/ui/icons/support.svg"
import ReportsIcon from "src/ui/icons/system/reports.svg"
import TeamsIcon from "src/ui/icons/teams.svg"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { spacing } from "src/ui/spacing"

import { SideNavLink } from "./SideNavLink"

export function SideNav({
  navLinkClick = () => {},
  ...props
}: {
  navLinkClick?: () => void
}) {
  const { showFlagsLink, installDevice: installEnabled } = useFlags()
  const { available: reportsAvailable } = useFeatureAvailability({
    feature: "reports",
  })

  const { org } = useOrganization()
  const orgRole = org.user_role

  const hasAdminAccess = getAccessLogic({ role: orgRole }).hasAdminAccess
  const permissions = usePermissions()

  const user = useGetUser()
  const { t } = useTranslate()
  const { isCancelled, isSuspended } = useDunning()

  const showMastekLogo = !!user.roles?.find((role: string) => role === "mastek")

  return (
    <SideNavBox
      {...props}
      aria-label="navbar"
      $background={colorsLegacy.backgroundGrayV2}
    >
      <LogoBox>
        {!!showMastekLogo ? <CustomLogo logo="mastek" /> : <Logo width={70} />}
      </LogoBox>

      <ProfileButtonSpacer>
        <ProfileButton />
      </ProfileButtonSpacer>

      <NavMain
        $color={mColors.textInactive}
        $activeColor={mColors.textPrimary}
        $hoverBgColor={mColors.neutralDark}
        $activeBgColor={mColors.neutralDark}
      >
        <>
          <li>
            <StyledNavLink
              to={Routes.Dashboard.location()}
              exact={true}
              onClick={navLinkClick}
            >
              <IconBox>
                <DashboardIcon />
              </IconBox>
              {t(langKeys.dashboard)}
            </StyledNavLink>
          </li>
          <li>
            <StyledNavLink
              to={Routes.Homes.location()}
              onClick={navLinkClick}
              disabled={isSuspended}
              tabIndex={isSuspended ? -1 : undefined}
            >
              <IconBox>
                <HomeIcon />
              </IconBox>
              {t(langKeys.properties, { count: 2 })}
            </StyledNavLink>
          </li>
          <li>
            <StyledNavLink
              to={Routes.Sensors.location({
                limit: DEVICE_LIST_LIMIT,
                offset: 0,
              })}
              onClick={navLinkClick}
              disabled={isSuspended}
              tabIndex={isSuspended ? -1 : undefined}
            >
              <IconBox>
                <SensorIcon />
              </IconBox>
              {t(langKeys.sensor, { count: 2 })}
            </StyledNavLink>
          </li>
          <li>
            <StyledNavLink
              to={{
                pathname: `/events`,
                search: `?limit=${EVENT_LIST_LIMIT}&offset=0`,
              }}
              onClick={navLinkClick}
              disabled={isSuspended}
              tabIndex={isSuspended ? -1 : undefined}
            >
              <IconBox>
                <EventsIcon />
              </IconBox>
              {t(langKeys.log)}
            </StyledNavLink>
          </li>

          {hasAdminAccess && <StyledDivider />}

          <Li hidden={!reportsAvailable || !permissions.reports}>
            <SideNavLink
              to={Routes.Reports.location()}
              icon={<ReportsIcon />}
              children={<span>{t(langKeys.reports_title)}</span>}
            />
          </Li>

          <Li hidden={!hasAdminAccess}>
            <StyledNavLink
              to={{
                pathname: Routes.MonitoringSettings.location().pathname,
              }}
              onClick={navLinkClick}
              disabled={isSuspended}
              tabIndex={isSuspended ? -1 : undefined}
            >
              <IconBox>
                <SettingsIcon />
              </IconBox>
              {t(langKeys.settings)}
            </StyledNavLink>
          </Li>

          <Li hidden={!hasAdminAccess}>
            <StyledNavLink
              to={Routes.OrgGuestCom.location()}
              onClick={navLinkClick}
              disabled={isSuspended}
              tabIndex={isSuspended ? -1 : undefined}
            >
              <IconBox>
                <GuestConnectIcon />
              </IconBox>
              {t(langKeys.guest_connect_title)}
            </StyledNavLink>
          </Li>

          <Li hidden={!hasAdminAccess}>
            <StyledNavLink
              to={Routes.OrgIntegrations.location()}
              onClick={navLinkClick}
              disabled={isSuspended}
              tabIndex={isSuspended ? -1 : undefined}
            >
              <IconBox>
                <IntegrationsIcon />
              </IconBox>
              {t(langKeys.integrations_title)}
            </StyledNavLink>
          </Li>

          <Li hidden={!hasAdminAccess}>
            <StyledNavLink
              to={Routes.AssistServices.location()}
              onClick={navLinkClick}
              disabled={isSuspended}
              tabIndex={isSuspended ? -1 : undefined}
            >
              <IconBox>
                <GuardAssistIcon />
              </IconBox>
              {t(langKeys.call_and_guard_assist)}
            </StyledNavLink>
          </Li>

          <StyledDivider />

          <Li>
            <StyledNavLink
              to={Routes.Organization.location()}
              onClick={navLinkClick}
              disabled={isCancelled}
              tabIndex={isSuspended ? -1 : undefined}
            >
              <IconBox>
                <TeamsIcon />
              </IconBox>
              {t(langKeys.team)}
            </StyledNavLink>
          </Li>

          <StyledDivider />
          {installEnabled && (
            <>
              <li>
                <StyledNavLink to="/install" onClick={navLinkClick}>
                  <IconBox>
                    <AddCircleIcon width={24} />
                  </IconBox>
                  {t(langKeys.install_minut)}
                </StyledNavLink>
              </li>
            </>
          )}

          <li>
            <SideNavLink
              to={Routes.ReferralProgram.location()}
              icon={<GiftIcon />}
              promotion="New"
            >
              {t(langKeys.referral_program_title)}
            </SideNavLink>
          </li>

          <li>
            <StyledExternalLink
              href={HREF_MINUT_STORE}
              aria-disabled={isSuspended ? true : undefined}
              tabIndex={isSuspended ? -1 : undefined}
            >
              <IconBox>
                <StoreIcon />
              </IconBox>
              {t(langKeys.store)}
            </StyledExternalLink>
          </li>

          <li>
            <StyledExternalLink href={HREF_MINUT_HELP}>
              <IconBox>
                <SupportIcon />
              </IconBox>
              {t(langKeys.support)}
            </StyledExternalLink>
          </li>

          {!!showFlagsLink && (
            <li>
              <StyledNavLink to="/flags" onClick={navLinkClick}>
                <IconBox>
                  <SettingsIcon />
                </IconBox>
                Flags
              </StyledNavLink>
            </li>
          )}
          {!!showFlagsLink && (
            <li>
              <StyledNavLink to="/sandbox" onClick={navLinkClick}>
                <IconBox>
                  <SettingsIcon />
                </IconBox>
                Sandbox
              </StyledNavLink>
            </li>
          )}
        </>
      </NavMain>
      <NavFooter></NavFooter>
    </SideNavBox>
  )
}

const StyledExternalLink = styled(ExternalLink)`
  padding: ${spacing.XS} ${spacing.XS};
  margin: 0 ${spacing.S};
  display: flex;
  align-items: center;
  transition: all 0.1s ease-in-out;
`

function Li({
  children,
  hidden,
}: {
  children: React.ReactNode
  hidden?: boolean
}) {
  if (hidden) return null
  return <li>{children}</li>
}
